import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="menu"
export default class extends Controller {
  static targets = ["navigation"];
  connect() {}
  toggle(event) {
    // cycle trhough each of the elements inside the button and toggle their visibility
    let t = event.currentTarget;
    for (let i = 0; i < t.children.length; i++) {
      t.children[i].classList.toggle("hidden");
    }

    this.navigationTarget.classList.toggle("hidden");
  }
}
