import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="map"
export default class extends Controller {
  static targets = ["map"];
  connect() {
    if (this.hasMapTarget) {
      // get location
      let lat = parseFloat(this.mapTarget.dataset.lat);
      let lon = parseFloat(this.mapTarget.dataset.lon);
      let location = { lat: lat, lng: lon };

      // init mapbox
      mapboxgl.accessToken = this.mapTarget.dataset.token;

      let map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/drinklikeawolf/cldzik06q001f01p5mbkgf688",
        center: location,
        zoom: 12,
      });

      // add zoom controls
      map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
          showZoom: true,
        })
      );

      // add a marker
      const marker = new mapboxgl.Marker({
        color: "000",
      })
        .setLngLat(location)
        .addTo(map);
    }
  }
}
